import request from '@/utils/request'

export function create(data) {
  return request({
    url: '/api/app/role',
    method: 'post',
    data
  })
}

export function getDetail(data) {
  return request({
    url: '/api/app/role/' + data.id,
    method: 'get'
  })
}

export function getAllPermissions(data) {
  return request({
    url: '/api/app/role/' + data.id + '/permissions',
    method: 'get'
  })
}

export function updateRolePermissions(data) {
  return request({
    url: '/api/app/role/' + data.id + '/permissions',
    method: 'put',
    data: data.permissions
  })
}

export function getAll(data) {
  return request({
    url: '/api/app/role',
    method: 'get',
    params: data
  })
}

export function update(data) {
  return request({
    url: '/api/app/role/' + data.id,
    method: 'put',
    data
  })
}

export function remove(data) {
  return request({
    url: '/api/app/role/' + data.id,
    method: 'delete'
  })
}

export function getRoles(data) {
  return request({
    url: '/api/app/role/list',
    method: 'get',
    params: data
  })
}

